import React, { useState } from 'react'
import Content from '../Content'
import PropTypes from 'prop-types'
import { KEYWORDS } from '../JobKeywordExplorer/keywords'
import { findKeywordEntry } from '../JobKeywordExplorer/utils'
import _ from 'lodash'
import {
  Sigma,
  RandomizeNodePositions,
  RelativeSize,
  EdgeShapes,
  NodeShapes,
  ForceAtlas2,
  NOverlap,
  SigmaEnableWebGL,
} from 'react-sigma'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
/**
 * Sigma:
 * https://dunnock.github.io/react-sigma/?path=/story/plugins--force-atlas-2
 *
 */

const EDGE_COLOR = '#5fc2ef'
const DARK_EDGE_COLOR = '#0b5577'
const ISA_NODE_COLOR = '#666'
const OTHER_NODE_COLOR = '#999'

const collectAllIsA = () => {
  const isA = []
  KEYWORDS.forEach(keyword => {
    if (!_.isEmpty(keyword.isa)) {
      keyword.isa.forEach(entry => {
        isA.push(entry)
      })
    }
  })

  return _.uniq(isA)
}

const convertToNodesAndEdges = () => {
  const data = {
    nodes: [],
    edges: [],
  }

  // guard against dupes
  const seenNodeKeysSet = new Set()
  const seenEdgeKeySet = new Set()
  const isANodes = collectAllIsA()
  // console.log("Isa", isANodes);

  KEYWORDS.forEach(keyword => {
    const { name, related, info, isa } = keyword
    const lowerName = _.toLower(name)
    const node = {
      id: lowerName,
      label: name,
      size: 20,
      color: OTHER_NODE_COLOR,
    }

    data.nodes.push(node)
    seenNodeKeysSet.add(lowerName)

    // link to other explicit rels
    if (!_.isEmpty(related)) {
      related.forEach(related => {
        const edge = {
          id: `${lowerName}_${_.toLower(related)}`,
          source: lowerName,
          target: _.toLower(related),
          color: EDGE_COLOR,
        }
        data.edges.push(edge)
        seenEdgeKeySet.add(edge.id)
      })
    }

    // Link to the main "isa" nodes
    if (!_.isEmpty(isa)) {
      isa.forEach(isaEntry => {
        const edge = {
          id: `${lowerName}_${_.toLower(isaEntry)}`,
          source: lowerName,
          target: _.toLower(isaEntry),
          color: DARK_EDGE_COLOR,
        }
        if (!seenEdgeKeySet.has(edge.id)) {
          data.edges.push(edge)
          seenEdgeKeySet.add(edge.id)
        }
      })
    }
  })

  isANodes.forEach(entry => {
    const node = {
      id: _.toLower(entry),
      label: entry,
      color: ISA_NODE_COLOR,
      // size: 20
    }
    if (!seenNodeKeysSet.has(node.id)) {
      data.nodes.push(node)
      seenNodeKeysSet.add(node.id)
    }
  })

  // console.log("graph", data);
  return data
}

export const DEMO_ELEMENTS = {
  nodes: [
    { data: { id: 'a', parent: 'b' }, position: { x: 215, y: 85 } },
    { data: { id: 'b' } },
    { data: { id: 'c', parent: 'b' }, position: { x: 300, y: 85 } },
    { data: { id: 'd' }, position: { x: 215, y: 175 } },
    { data: { id: 'e' } },
    { data: { id: 'f', parent: 'e' }, position: { x: 300, y: 175 } },
  ],
  edges: [
    { data: { id: 'ad', source: 'a', target: 'd' } },
    { data: { id: 'eb', source: 'e', target: 'b' } },
  ],
}

const KeywordGraphPageTemplate = ({
  title,
  description,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content

  const graphElements = convertToNodesAndEdges()

  const throttledToast = _.throttle(e => {
    console.log('Mouse over node: ', e.data.node)
    const keyword = findKeywordEntry(e.data.node.id)
    let message
    if (keyword) {
      message = (
        <div>
          <h3 className='has-text-white is-size-5'>{keyword.name}</h3>
          {keyword.info}
        </div>
      )
    } else {
      message = (
        <div>
          <h3 className='has-text-white is-size-5'>
            {_.capitalize(e.data.node.label)}
          </h3>
        </div>
      )
    }
    toast.info(message)
  }, 1000)

  return (
    <div>
      <section className='hero is-primary is-bold no-print'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns'>
              <div className='column is-10 is-offset-1'>
                <div className='section'>
                  <h1 className='title has-text-white is-size-2'>{title}</h1>
                  <h2 className='description has-text-white is-size-4'>
                    {description}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='section section--gradient'>
        <div className='container'>
          <div className='columns'>
            <div className='column is-12'>
              This graph is generated from relationships based on the keywords
              in the keyword tool. I've used{' '}
              <a href='http://sigmajs.org/'>sigma.js</a> to render the graph
              (randomly). The graph uses a webworker to generate the layout and
              webgl to render the graph.
            </div>
          </div>
        </div>
        <div>
          {typeof window !== `undefined` ? (
            <Sigma
              renderer='webgl'
              graph={graphElements}
              onOverNode={throttledToast}
              settings={{
                drawEdges: true,
                defaultLabelSize: 15,
                drawLabels: true,
                labelSize: 'fixed',
                labelThreshold: 3,
                defaultEdgeColor: '#5fc2ef',
                defaultNodeColor: '#333',
                labelColor: '#999',
                minArrowSize: 1,
              }}
              style={{
                minWidth: '500px',
                minHeight: '900px',
                height: '100%',
                width: '100%',
              }}
            >
              <EdgeShapes default='tapered' />
              <NodeShapes default='star' />
              <RandomizeNodePositions>
                <ForceAtlas2
                  iterationsPerRender={10}
                  linLogMode
                  timeout={10000}
                  worker
                  scalingRatio={10}
                  // barnesHutOptimize
                  // barnesHutTheta={1}
                />
                <RelativeSize initialSize={15} />
              </RandomizeNodePositions>
            </Sigma>
          ) : (
            <div>The graph cannot be server side rendered</div>
          )}
        </div>
      </section>
      <ToastContainer
        position='bottom-right'
        autoClose={6000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
    </div>
  )
}

KeywordGraphPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

export default KeywordGraphPageTemplate
