import { KEYWORDS, TOKEN_MAP } from './keywords'
import Tokenizer from 'tokenize-text'
import _ from 'lodash'

export const cleanAlts = keywordEntry => {
  if (!keywordEntry.alts) {
    return []
  }
  return keywordEntry.alts.map(alt => {
    return _.toLower(alt)
  })
}

export const findKeywordEntry = value => {
  const cleaned = _.trim(_.toLower(value))
  return KEYWORDS.find(keywordEntry => {
    const keywordName = _.toLower(keywordEntry.name)
    const altsCleaned = cleanAlts(keywordEntry)
    return cleaned === keywordName || altsCleaned.includes(cleaned)
  })
}

export const filterKeywordPartial = searchTerm => {
  const cleaned = _.trim(_.toLower(searchTerm))
  return KEYWORDS.filter(keywordEntry => {
    const keywordName = _.toLower(keywordEntry.name)
    const altsCleaned = cleanAlts(keywordEntry)
    return keywordName.includes(cleaned) || altsCleaned.includes(cleaned)
  })
}

/**
 * This function takes text a returns an array of annotated tokens in format:
 *
 * {
 *    original: String, raw token text,
 *    cleaned: String, clean token text,
 *    position: Int, where is it in the string?
 * }
 */
export const tokenizeText = text => {
  if (!text) return []

  const tokenizer = new Tokenizer()
  const tokens = tokenizer.words()(text)
  if (!tokens) return []

  const annotatedTokens = []
  tokens.forEach(({ value, index, offset }) => {
    const cleaned = _.trim(_.toLower(value))
    // this is where we match them to keywords we know of
    const match = findKeywordEntry(cleaned)
    annotatedTokens.push({
      cleaned,
      value,
      index,
      offset,
      match,
    })
  })
  return annotatedTokens
}

/**
 * This function takes a set of tokens and returns a map of keywords
 */
export const tokensToKeywords = tokens => {
  const matchedKeywords = new Set()

  tokens.forEach(token => {
    // find matching keyword
    const match = findKeywordEntry(token.cleaned)
    if (match) {
      matchedKeywords.add(match)
    }
  })

  return matchedKeywords
}

function spliceSlice (str, index, count, add) {
  // We cannot pass negative indexes directly to the 2nd slicing operation.
  if (index < 0) {
    index = str.length + index
    if (index < 0) {
      index = 0
    }
  }

  return str.slice(0, index) + (add || '') + str.slice(index + count)
}

export const formatTextWithTooltips = text => {
  let outputText = text

  // replace each in the token map
  Object.keys(TOKEN_MAP).forEach(key => {
    const entry = TOKEN_MAP[key]
    outputText = _.replace(outputText, entry.regex, entry.replace)
  })
  // console.log("Text after token map", outputText);

  // We re-do it here otherwise it will fuck up my index positions
  const tokens = tokenizeText(outputText)

  let offsetUpdate = 0
  tokens.forEach(token => {
    if (token.match) {
      // only replace after the index position?
      // replace and then record new offset?

      // keyword info
      const { name, info } = token.match

      // token info
      const { cleaned, value, index, offset } = token
      // The tooltip formatted span
      // data-customrh -> needed to trigger tip
      // data-name={`${_.toLower(rel)}`} -> we match on keyword name with this
      const toolTipText = `<span data-customrh data-name="${_.toLower(
        name
      )}" class="kwti">${value}</span>`

      // replace it based on position
      const start = index + offsetUpdate
      // console.log(
      //   "Doing a splice",
      //   value,
      //   index,
      //   offset,
      //   offsetUpdate,
      //   toolTipText
      // );
      outputText = spliceSlice(outputText, start, offset, toolTipText)

      // update offset
      const offsetDiff = toolTipText.length - offset
      offsetUpdate += offsetDiff
    }
  })

  // Replace tokens back
  Object.keys(TOKEN_MAP).forEach(key => {
    const entry = TOKEN_MAP[key]
    outputText = _.replace(outputText, new RegExp(entry.replace, 'g'), key)
  })

  // fix linebreaks with newlines
  outputText = _.replace(outputText, /\n|\r/g, '<br />')

  return {
    formattedText: outputText,
    tokens,
  }
}
